<template>
  <!-- 逾期天数统计 -->
  <div class="home">
    <div class="searchNav">
      <!-- <el-button
        class="button"
        icon="el-icon-document"
        type="primary"
        style="margin-left: 0"
        :loading="addloading"
        @click="downloadFile"
        >逾期天数统计逾期天数统计逾期天数统计逾期天数统计逾期天数统计</el-button
      > -->

      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
      </div>
    </div>
    <div class="searchNav">
      <div class="searchParams" style="justify-content: flex-start">
        <!-- <el-date-picker v-model="daterange" type="daterange" style="width: 250px; margin-left: 10px"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="changeTime">
        </el-date-picker>
        <el-select class="input1" v-model="optionsKey" placeholder="请选择搜索类型" @change="selectChange">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <el-input style="margin-left: 10px" class="input1" v-model="queryParam.Keywords"
          :placeholder="'请输入' + placeholder + '名称进行查询'" @keydown.enter.native="Getdata"></el-input>
        <el-button class="button" @click="Getdata" type="primary">查询</el-button>
      </div>
    </div>
    <el-table ref="multipleTable" :data="tableData" style="width: 100%" :max-height="height" v-loading="loading"
      @selection-change="handleSelectionChange">
      <el-table-column prop="cCusName" :label="label" width="280">
      </el-table-column>
      <el-table-column prop="" label="金额" width="800">
        <el-table-column prop="SevenDays" label="0-7天" width="340">
        </el-table-column>
        <el-table-column prop="ThirtyDays" label="8-30天" width="340">
        </el-table-column>
        <el-table-column prop="SixtyDays" label="31-60天" width="340">
        </el-table-column>
        <el-table-column prop="SixtyUpperDays" label="60天以上" width="340">
        </el-table-column>
      </el-table-column>
    </el-table>
    <div style="display: flex">
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="margin-top: 8px; padding: 0; flex: 5" @current-change="currentChange" @size-change="handleSizeChange">
      </el-pagination>
      <el-row :gutter="20"
        style=" text-align: right; font-weight: bold;padding:10px 0;flex:6;display:flex;justify-content: flex-end;padding-right:20px">
        <el-col v-if="selectOptions==1" :span="5">
          <div style="">总数量: {{ totalNum }}</div>
        </el-col>
        <el-col :span="5">
          <div style="">0-7天: {{ toThousands(overdueDayObject.SevenDays) }}</div>
        </el-col>
        <el-col :span="5">
          <div style="">8-30天: {{ toThousands(overdueDayObject.ThirtyDays) }}</div>
        </el-col>
        <el-col :span="5">
          <div style="">31-60天: {{ toThousands(overdueDayObject.SixtyDays) }}</div>
        </el-col>
        <el-col :span="5">
          <div style="">60天以上: {{ toThousands(overdueDayObject.SixtyUpperDays) }}</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import radioGroup from "@/components/CompanyRadioGroup.vue";
import { toThousands } from "../../utils/Tools";
import MyBreadcrumb from '../../components/myBreadcrumb.vue';
export default {
  name: "Home",
  components: {
    radioGroup,
    MyBreadcrumb,
    // waybillForm
  },
  data() {
    return {
      // 选择的是客户 货品 还是业务员
      selectOptions: 0,
      // 显示客户  还是人员
      label: '客户',
      // 日期
      daterange: [],
      // 默认下拉选项的值
      optionsKey: '客户',
      placeholder: "请输入客户名称进行查询",
      // 金额格式化工具
      toThousands,
      // 搜索类型下拉数据
      options: [{
        value: '0',
        label: '客户'
      },
      {
        value: '1',
        label: '货品'
      },
      {
        value: '2',
        label: '业务员'
      }],
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      // 逾期天数统计列表数据
      tableData: [],
      // 查询列表参数
      queryParam: {
        CompanyId: "",
        StartTime: "",
        EndTime: "",
        Keywords: "",
        Type: 0
      },
      loading: false,
      // 逾期天数底部总金额统计数据
      overdueDayObject: {
        // 、0-7天
        SevenDays: 0,
        // 、8-30天
        ThirtyDays: 0,
        // 、31-60天
        SixtyDays: 0,
        // 60天以上
        SixtyUpperDays: 0
      }
    };
  },
  mounted() { },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 搜索下拉改变
    selectChange(e) {
      console.log('选择的是', e);
      this.selectOptions = e
      this.queryParam.Type=e
      if (e == 0) {
        this.optionsKey = '客户';
        this.label = '客户'
        this.placeholder = '客户'
      } else if (e == 1) {
        this.optionsKey = '货品';
        this.label = '货品'
        this.placeholder = '货品'
      } else {
        this.optionsKey = '业务员';
        this.label = '业务员'
        this.placeholder = '业务员'
      }
      this.pagination.current=1
      this.getDataList()
      this.getGetLinqBillingStatistics()
    },
    // 时间选择
    changeTime(e) {
      console.log(e);
      if (e) {
        this.queryParam.StartDate = e[0];
        this.queryParam.EndDate = e[1];
      } else {
        this.queryParam.StartDate = ''
        this.queryParam.EndDate = ''
      }

      // 清空列表数据
      this.pagination.current=1
      // 获取逾期天数统计列表数据
      this.getDataList()
      this.getGetLinqBillingStatistics()
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectRows = rows.map((item) => item.evidenceId);
    },
    radioComplete(e) {
      console.log(e);
      if (e) {
        this.queryParam.CompanyId = e;
        // 获取逾期天数统计列表数据
        this.getDataList();
        // 获取逾期天数总金额统计数据
        this.getGetLinqBillingStatistics();
      }
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.CompanyId = e;
      this.getDataList();
      this.getGetLinqBillingStatistics();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    Getdata() {
      this.loading = true
      this.pagination.current = 1
      this.tableData=[]
      this.getDataList();
      // this.getGetLinqBillingStatistics()
    },

    // 获取逾期天数金额总计数据
    getGetLinqBillingStatistics() {
      this.$http.post("/BO/BIlling/GetDaysAllStatistics", {
        PageIndex: this.pagination.current,
        PageRows: this.pagination.pageSize,
        Search: this.queryParam
      }).then((resJson) => {
        if (resJson.Success) {
          console.log('获取逾期天数金额金额金额额金额金额统计数据额金额金额统计数据额金额金额统计数据统计数据', resJson.Data)
          if (resJson.Data == null) {
            this.overdueDayObject = {
                // 、0-7天
                SevenDays: 0,
                // 、8-30天
                ThirtyDays: 0,
                // 、31-60天
                SixtyDays: 0,
                // 60天以上
                SixtyUpperDays: 0
            }
            return
          }
          this.overdueDayObject.SevenDays = resJson.Data.SevenDays
          this.overdueDayObject.ThirtyDays = resJson.Data.ThirtyDays
          this.overdueDayObject.SixtyDays = resJson.Data.SixtyDays
          this.overdueDayObject.SixtyUpperDays = resJson.Data.SixtyUpperDays

        } else {
          this.loading = false
        }
      });
    },

    // 获取逾期天数统计列表数据
    getDataList() {
      this.loading = true;
      let usr = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(usr.UserPhone);
      this.$http
        .post("BO/BIlling/GetDaysStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            if(resJson.Data==null ||resJson.Data.length==0){
              this.tableData=[]
              this.overdueDayObject = {
                  // 、0-7天
                  SevenDays: 0,
                  // 、8-30天
                  ThirtyDays: 0,
                  // 、31-60天
                  SixtyDays: 0,
                  // 60天以上
                  SixtyUpperDays: 0
              }
              return 
            }
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}

.input1 {
  width: 200px;
  margin-left: 10px;
}
.button{
  background-color: #d50700;
  border: #d50700;
}

.button:hover {
  background-color: #d50700;
}
</style>
